import React from "react";
import "./Home.css";
import {Nav} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {LinkContainer} from "react-router-bootstrap";

export default function Home() {
    return (
        <>
            <style>
                {`
                   body, html {
    height: 100%;
}
.lander-left {
	background-image:url("images/home-bg.jpg");
}
                `}
            </style>


            <div className="Home">
                <div className="container">
                    <div className="lander">
                        <div className="lander-left">
                            <h1>Walk Through</h1>
                        </div>
                        <div className="lander-right"><h1>Welcome to <span>Walk</span>Through</h1>
                            <div className="art-img"><img src="images/draw-img.png" alt=""/></div>
                            <ul className="text-muted">
                                <li>Create</li>
                                <li>Communicate</li>
                                <li>Collaborate</li>
                            </ul>
                            <p className="text-sec">The perfect tool for streamlined project communication Connect the
                                physical world with all the necessary digital media elements</p>
                            <div className="Available-content">Available on
                                <div className="Available-icons">
                                    {/* <img src="icons/app_store.png" alt=""/>*/}
                                    <a href="https://apps.apple.com/us/app/walkthrough-by-tasktape/id1336050583?ls=1"
                                       target="_blank"> <img src="/icons/ios_icon.png" alt="ios_icon"/> </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.solipsar.tasktape.walkthrough.dev&ah=QDNLFWRDhWF05wzGIv4J3vnBsjA"
                                       target="_blank"> <img src="/icons/android_icon.png" alt="android_icon"/> </a>
                                </div>
                            </div>


                            <ul className="bottom-icons">
                                <li>
                                    <div className="icon-bg"><img src="icons/Group 203.png" alt=""/></div>
                                    <p>Photo</p></li>
                                <li>
                                    <div className="icon-bg"><img src="icons/Group 202.png" alt=""/></div>
                                    <p>Video</p></li>
                                <li>
                                    <div className="icon-bg"><img src="icons/Group 201.png" alt=""/></div>
                                    <p>Audio</p></li>
                                <li>
                                    <div className="icon-bg"><img src="icons/Group 302.png" alt=""/></div>
                                    <p>Note</p></li>

                            </ul>
                        </div>
                    </div>
                    <div className="getJobsList">
                        <LinkContainer to="/jobs/list"
                                       data="a">
                            <Nav.Link>
                                <span className="fa-layers fa-fw" style={{"padding":"20px"}}>
                                  <FontAwesomeIcon icon="circle" color="#1a67d3" size="4x"/>
                                  <FontAwesomeIcon icon="list-ul" color="#1a67d3" inverse
                                                   transform="shrink-7" size="4x"/>
                                </span>
                                <span  style={{"padding":"40px"}}>View My Jobs List</span>
                            </Nav.Link>
                        </LinkContainer>
                    </div>
                </div>
            </div>
        </>
    );
}
