import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {onError} from "../libs/errorLib";
import Api from "../libs/api";
import {useAppContext} from "../libs/contextLib";
import {v4 as uuidv4} from "uuid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LoaderButton from "./LoaderButton";
import {OWNER} from "../libs/authorization";

export default function ShareJob(props) {

    const [email, setEmail] = useState(null);
    const [receiverRole, setReceiverRole] = useState("ROLE_VIEWER");
    const [isSending, setIsSending] = useState(false);
    const [errors, setErrors] = useState({});
    const { user } = useAppContext();
    const api = Api();

    useEffect(() => { //IF no args then run only once per render I think?

    }, []);

    function isValidEmail() {
        let pattern = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i);

        if (!pattern.test(email)) {

            errors["email"] = "Please enter valid email address.";

            return false;

        }
        errors["email"] = "";
        return true;
    }

    async function sendShareInvite(event) {
        event.preventDefault();

        setIsSending(true);

        try {

            const newShareUuid = uuidv4();

            //Create the share record
            await api.shareJob(newShareUuid, createShare(newShareUuid));

            //Tell the backend to share through email provider
            await api.emailShare(newShareUuid, emailShare());

            setIsSending(false);

            if (props.closeModal) {
                props.closeModal();
            }

        } catch (e) {
            onError(e);
            setIsSending(false);
        }
    }

    function createShare(newShareUuid) {
        // const shareUuid = ; //Lets look at moving this construct to server-side?
        let shareContext = 'app';
        if (process.env.REACT_APP_STAGE !== "prod") {
            shareContext = 'app-dev';
        }
        return {
            "fromUuid": user.userUuid,
            "shareReceiverRole": receiverRole,
            "link": `https://www.tasktape.com/shares?id=${newShareUuid}&context=${shareContext}`,
            "shareSenderRole": "ROLE_OWNER",
            "shareContextRefUuid": props.jobUuid,
            "shareContext": "JOB"
        }
    }

    function emailShare() {
        const userName = user.firstName ? user.firstName + " " + user.lastName : user.email;
        return {
            "email": email,
            "userName": userName,
            "jobName": props.jobName
        }
    }

    return (
        <>
            <FontAwesomeIcon icon="envelope-open-text" color="#1a67d3" size="2x" /> Sharing via email:

            <Form>
                <Form.Group controlId="emailAddress" size="lg">
                    <Form.Control
                        autoFocus
                        as="input"
                        onChange={e => setEmail(e.target.value)}
                        disabled={isSending}
                        value={email}
                        placeholder="some@where.com"
                    />
                    <div className="text-danger">{errors.email}</div>
                </Form.Group>
                <p>
                    When you share a job that you created, the recipient can receive the share as different roles
                    <li>Worker - can reply in the feed with text and images</li>
                    <li>Owner - can edit details, create Areas/TaskViews/TaskTapes + Worker abilities</li>
                </p>
                <Form.Group controlId="shareRole" size="lg">
                    <Form.Group controlId="exampleForm.SelectCustomSizeLg">
                        <Form.Label>Share as...</Form.Label>
                        <Form.Control as="select" size="lg" custom
                                      onChange={e => setReceiverRole(e.target.value)}
                                      disabled={isSending || props.userRoleInJob !== OWNER}
                        >
                            <option value="ROLE_VIEWER">Worker</option>
                            <option value="ROLE_OWNER">Owner</option>
                        </Form.Control>
                    </Form.Group>
                    <div className="text-danger"></div>
                </Form.Group>
                <LoaderButton
                    size="md"
                    type="save"
                    variant="primary"
                    isLoading={isSending}
                    disabled={!isValidEmail()}
                    onClick={(e) => sendShareInvite(e)}
                >
                    Send
                </LoaderButton>
            </Form>
        </>
    );
}
