

import React, {useEffect, useRef, useState} from "react";
import ImageUploader from "react-images-upload"; // https://github.com/jakehartnell/react-images-upload#readme
import { v4 as uuidv4 } from 'uuid';
import Form from "react-bootstrap/Form";
import {Link, useHistory} from "react-router-dom";

import { onError } from "../../libs/errorLib";
import config from "../../config";
import "./JobsList.css";
import Api from "../../libs/api";
import {useAppContext} from "../../libs/contextLib";
import LoaderButton from "../../components/LoaderButton";
import CroppableImage from "../../components/CroppableImage";
import {Col, Container, Nav, Row} from "react-bootstrap";
import {useFormFields} from "../../libs/hooksLib";
import Images from "../../libs/image";
import _ from "lodash";
import * as models from "../../libs/models";
// import axios from "axios";
import { GeocoderAutocomplete } from '@geoapify/geocoder-autocomplete';

export default function CreateJob(props) {

    const isUpdate = props.selectedJob !== null;

    const { isAuthenticated, user } = useAppContext();
    const [src, setSrc] = useState(null);

    const [fileUrl, setFileUrl] = useState(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);

    const api = Api();
    const images = Images();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [job, setJob] = useState(props.selectedJob ? JSON.parse(JSON.stringify(props.selectedJob)) : jobBlueprint(user));

    const [fields, handleFieldChange] = useFormFields({
        name: job.name || "",
        description: job.description || "",
        address: job.address.streetAddress || "",
        city: job.address.city || "",
        state: job.address.state || "",
        country: job.address.country || "",
        postCode: job.address.postCode || ""
    });

    useEffect(() => {
        if (!isAuthenticated) {
            history.push(`/login`)
        }

    }, [props.selectedJob])

    const onDrop = picture => {

        const fileReader = new FileReader();

        const file = picture[0];

        fileReader.onloadend = () => {
            // setJobImage({photoFile: file, photoUrl: fileReader.result});
            setSrc(fileReader.result);
        }
        if(file) {
            setFileUrl(fileReader.readAsDataURL(file));
        } else {
            setSrc(null);
        }

        console.log(`Image dropped ${JSON.stringify(file)}`)

    };

    useEffect(() => { //IF no args then run only once per render I think?
        if (!isAuthenticated) { // TODO: Figure out why this is soemtimes true when we just logged in!} || user.userUuid === undefined) {
            //TODO: Isn't/can't this be handled by app-level js? We can't have to repeat this in every file
            history.push(`/login`)
        }

        // const geoNode = document.getElementById("geoapify-autocomplete");
        // while (geoNode.firstChild) {
        //     geoNode.removeChild(geoNode.lastChild);
        // }
        const autocomplete = new GeocoderAutocomplete(
          document.getElementById("geoapify-autocomplete"),
          config.geoapify.apiKey,
          {
              lang: 'en',
              // type: 'street',
              limit: 5,
              placeholder: 'job location',
              debounceDelay: '200ms',
              skipDetails: true
          });

        autocomplete.setValue(job.address.streetAddress);

        autocomplete.on('select', (location) => {
            console.log(`user selected ${JSON.stringify(location)}`);

            if (location) {
                fields.city = location.properties.city;
                fields.state = location.properties.state;
                fields.postCode = location.properties.postcode;
                fields.address = location.properties.address_line1;
                job.address.city = location.properties.city;
                job.address.state = location.properties.state;
                job.address.postCode = location.properties.postcode;
                job.address.streetAddress = location.properties.address_line1;
                job.address.stateCode = location.properties.state_code;
                job.address.county = location.properties.county;
                job.address.country = location.properties.country;
                job.address.countryCode = location.properties.country_code;
                job.address.geolocation = job.address.geolocation || {};
                job.address.geolocation.lat = location.properties.lat;
                job.address.geolocation.lng = location.properties.lon;
                job.address.validated = true;
                setJob(job);
            } else {
                job.address = {};
                job.address.geolocation = {};
                job.address.geolocation.lat = 0;
                job.address.geolocation.lng = 0;
                job.address.validated = false;
                setJob(job);
            }

            /**
             * user selected
             * {
    "type": "Feature",
    "geometry": {
        "type": "Point",
        "coordinates": [
            -97.868846,
            30.171292
        ]
    },
    "properties": {
        "housenumber": "4110",
        "street": "Aldama Dr",
        "country": "United States",
        "county": "Travis County",
        "datasource": {
            "sourcename": "openaddresses",
            "attribution": "© OpenAddresses contributors",
            "license": "BSD-3-Clause License"
        },
        "country_code": "us",
        "postcode": "78739",
        "state": "Texas",
        "city": "Austin",
        "suburb": "Shady Hollow",
        "lon": -97.868846,
        "lat": 30.171292,
        "formatted": "4110 Aldama Dr, Austin, TX 78739, United States of America",
        "address_line1": "4110 Aldama Dr",
        "address_line2": "Austin, TX 78739, United States of America",
        "state_code": "TX",
        "result_type": "building",
        "rank": {
            "confidence": 1,
            "match_type": "full_match"
        },
        "place_id": "51abd0402c9b7758c0590211e2cad92b3e40c00203"
    }
}
**/
        });

        autocomplete.on('suggestions', (suggestions) => {
            // process suggestions here
        });

    }, [])

    function validateForm() { // TODO: Toast "bad inputs" https://react-bootstrap.github.io/components/toasts/
        if (fields.name.length > 2 &&
          (job.address.validated ||
            (
                fields.address.length > 3 &&
                fields.city.length > 2 &&
                fields.state.length > 1 &&
                fields.postCode.length > 4
            )) &&
            (
                (
                    croppedImageUrl && croppedImageUrl.length > 0 // We've added an image - new job case
                ) || (job.jobImage && job.jobImage.contentUrl) // An image already exists - update case
            )
        ) {
            job.name = fields.name;
            job.description = fields.description;

            if (!job.address.validated) {
                if (! job.address) {
                    job.address = {};
                }
                job.address.streetAddress = fields.address;
                job.address.city = fields.city;
                job.address.state = fields.state;
                job.address.postCode = fields.postCode;
            }
            return true;
        }
        return false;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);

        //TODO: Componentize

        const svcCredentials = await api.awsSvcCredentials();

        if (!svcCredentials.authenticated) {
            //TODO: Toast
            alert("Session timed out. Please re-login");
            props.closeModal();
            history.push("/login");

        }

        let jobUuid = undefined;

        function prepJob() {

            if (! isUpdate && ! job.jobUuid) {
                job.createdUser = user.userUuid;
                jobUuid = uuidv4(); //Because jobUuid is set into job on server based on path var, we don't set it INTO Job here.


                if (!job.address.geolocation) {
                    job.address.geolocation = {};
                    job.address.geolocation.lat = 0;
                    job.address.geolocation.lng = 0;
                    job.address.validated = false;
                }

            } else {
                jobUuid = job.jobUuid;
            }

            //Manage Image if changed
            if (croppedImageUrl) {
                /**
                 *
                 * {
                        -- "contentUrl": "https://s3.amazonaws.com/023464524600-tasktape-dev/users/c1db9926-4a0a-4089-8dbf-ea667eae5434/jobs/8A675488-5A2B-472A-9564-0E395145EB18/job-5A90C58E-28C9-40DD-8085-3CE145B78C55.jpg",
                        "width": 0, - can't we get this?
                        -- "mediaUuid": "F0C00B65-8D92-485E-AEBE-DD744DC58756",
                        "originalUrl": "https://s3.amazonaws.com/023464524600-tasktape-dev/users/c1db9926-4a0a-4089-8dbf-ea667eae5434/jobs/8A675488-5A2B-472A-9564-0E395145EB18/job-5A90C58E-28C9-40DD-8085-3CE145B78C55.jpg",
                        "createdOn": 1619236522332,
                        "createdUser": "c1db9926-4a0a-4089-8dbf-ea667eae5434",
                        -- "height": 0 - can't we get this?
                      }
                 */

                const serverUrl = images.getStorageURL(images.getJobImageStorageKey(user, jobUuid));
                job["jobImage"] = models.newImage(user, serverUrl, serverUrl);

                  /*{
                    "mediaUuid": uuidv4(),
                    "originalUrl": images.getStorageURL(images.getJobImageStorageKey(user, jobUuid)),
                    "contentUrl": images.getStorageURL(images.getJobImageStorageKey(user, jobUuid)),
                    "description": "Job Image",
                    "width": 0,
                    "createdOn": new Date().getTime(),
                    "createdUser": user.userUuid
                }*/
            }

            if (!job.jobImage) {
                job.jobImage = {};
            }

            //As inane as this seems, this is a holdover from a bug in the original IOS development
            // where the job description was being stuck inside the job image (a required field)
            delete job.description;
            job.jobImage.description = fields.description;

        }

        prepJob();

        console.log(`Submitting job ${jobUuid} ${JSON.stringify(job)}`);

        try {
            console.log(`IMAGE: ${croppedImageUrl}`);
            const updatedJob = await api.putJob(job, jobUuid);

            await pushJobImageToS3(jobUuid);

            setIsLoading(false);

            setJob(updatedJob);

            props.addJob(updatedJob, isUpdate);

            // history.push("/jobs/list");
            props.closeModal();
        } catch (e) {
            console.error(e);
            onError(e);
            setIsLoading(false);
        }
    }

    async function pushJobImageToS3(jobUuid) {

        const key = images.getJobImageStorageKey(user, jobUuid);
        console.log(`Writing ${croppedImageUrl} to S3 at : ${key} `);

        const imageBlob = await fetch(croppedImageUrl)
            .then(res => res.blob()) // Gets the response and returns it as a blob
            .then(blob => {
                return blob;
            });

        await api.pushObjectToS3(key, imageBlob);

    }

    return (
        <Form onSubmit={handleSubmit}>
        <Container>
            <Row>
                <Col className="upper-image-main">
                    {(job.jobImage && job.jobImage.contentUrl) !== undefined && !croppedImageUrl &&
                    <img src={images.getUrlForResizedImage(job.jobImage.contentUrl, 200)} />
                    }
                    <ImageUploader
                        {...props}
                        withPreview={false}
                        withIcon={true}
                        onChange={onDrop}
                        buttonText={"Choose Job Image"}
                        label={"Max 5mb, png or jpg"}
                      // buttonStyles={"submit"}
                        imgExtension={[".png", ".jpg", ".jpeg"]}
                        maxFileSize={5242880}
                        singleImage={true}
                    /><CroppableImage src={src} setSrc={setSrc} fileUrl={fileUrl} croppedImageUrl={croppedImageUrl} setCroppedImageUrl={setCroppedImageUrl}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="name">
                        <Form.Control
                            autoFocus
                            type="text"
                            value={fields.name}
                            placeholder="Job name"
                            disabled={isLoading}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <div id="geoapify-autocomplete" className="autocomplete-container"/>
                    </Form.Group>
                </Col>
            </Row>
            {! job.address.validated &&

            <Row>
                <Col>
                    <Form.Group controlId="city">
                        <Form.Control
                            autoFocus
                            type="text"
                            value={fields.city}
                            placeholder="City"
                            disabled={isLoading}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="state">
                        <Form.Control
                            autoFocus
                            type="text"
                            value={fields.state}
                            placeholder="State"
                            disabled={isLoading}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="postCode">
                        <Form.Control
                            autoFocus
                            type="text"
                            value={fields.postCode}
                            placeholder="Zip code"
                            disabled={isLoading}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            }
            <Row>
                <Col>
                    <Form.Group controlId="description">
                        <Form.Control
                            autoFocus
                            as="textarea"
                            rows={3}
                            value={fields.description}
                            placeholder="Job description"
                            disabled={isLoading}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <LoaderButton
                        block
                        size="lg"
                        type="submit"
                        isLoading={isLoading}
                        disabled={!validateForm() || isLoading}
                    >
                        Save
                    </LoaderButton>
                </Col>
            </Row>

        </Container>
        </Form>

    )

    function jobBlueprint(user) {
        // return {
        //     "jobUuid": null,
        //     "providerId": "walkthrough",
        //     "name": "Job A",
        //     "isPaid": false,
        //     "userRoleInJob": "ROLE_OWNER",
        //     "address": {
        //         "city": "Cityville",
        //         "country": "US",
        //         "postCode": "12312",
        //         "state": "NW",
        //         "streetAddress": "123 Nowhere Lane",
        //         "timezone": "CST"
        //     },
        //     "jobImage": {
        //         "mediaUuid": null,
        //         "originalUrl": null,
        //         "contentUrl": null,
        //         "createdOn": 0,
        //         "createdUser": user.userUuid,
        //         "description": "string"
        //     },
        //     "locations": []
        // }
        return {
            "jobUuid": null,
            "providerId": "walkthrough",
            "name": "",
            "description": "For this job we need to...",
            "isPaid": false,
            "userRoleInJob": "ROLE_OWNER",
            "address": {
                "city": null,
                "country": "US",
                "postCode": null,
                "state": null,
                "streetAddress": null,
                "timezone": null
            },
            "jobImage": {
                "mediaUuid": null,
                "originalUrl": null,
                "contentUrl": null,
                "createdOn": 0,
                "createdUser": user.userUuid,
                "description": "string"
            },
            "locations": [
            ]
        }
    }
}